import React, { useState, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  fetchProjectSettings,
  udpateProjectSettings,
  addLinkedinAccessToken,
  deleteIntegration
} from 'Reducers/global';
import { Row, Col } from 'antd';
import { Text, FaErrorComp, FaErrorLog, SVG } from 'factorsComponents';
import { ErrorBoundary } from 'react-error-boundary';
import factorsai from 'factorsai';
import { sendSlackNotification } from '../../../../../utils/slack';
import { getBackendHost } from '../util';
import NumberFormat from 'react-number-format';
import UiIcon from 'Components/UiIcon';
import UiSkeleton from 'Components/UiSkeleton';
import logger from 'Utils/logger';
import styles from './index.module.scss';
import UiModal from 'Components/UiModal';
import UiCheckbox from 'Components/UiCheckbox';
import UiButton from 'Components/UiButton';
import UiInput from 'Components/UiInput';
import UiMessage from 'Components/UiMessage';

const LinkedInIntegration = ({
  fetchProjectSettings,
  udpateProjectSettings,
  activeProject,
  currentProjectSettings,
  addLinkedinAccessToken,
  deleteIntegration,
  currentAgent,
  integrationCallback
}) => {
  // State declarations
  const [loading, setLoading] = useState(false);
  const [adAccounts, setAdAccounts] = useState(null);
  const [selectedAdAccount, setSelectedAdAccount] = useState([]); // array of account IDs as strings
  const [showForm, setShowForm] = useState(false);
  const [oauthResponse, setOauthResponse] = useState(null);
  const [adAccountLoading, setAdAccountLoading] = useState(true);
  const [showManualModal, setShowManualModal] = useState(false);
  const [manualAccount, setManualAccount] = useState('');

  // Helper: convert API response into account objects
  const getAdAccounts = (jsonRes) => {
    return jsonRes.map((res) => ({
      value: res.id,
      name: res.name,
      status: res.status
    }));
  };

  // Fetch LinkedIn ad accounts
  const fetchAdAccounts = useMemo(() => {
    return () => {
      const url = `${getBackendHost()}/integrations/linkedin/ad_accounts`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({
          access_token: currentProjectSettings?.int_linkedin_access_token
        })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Failed to fetch');
          }
          return response;
        })
        .then((response) => {
          response.json().then((res) => {
            const jsonRes = JSON.parse(res);
            const adAccountsNew = getAdAccounts(jsonRes.elements);
            setAdAccounts(adAccountsNew);
            setAdAccountLoading(false);
          });
        })
        .catch((err) => {
          logger.error('Failed to fetch linkedin/ad_accounts', err);
          setAdAccountLoading(false);
        });
    };
  }, [currentProjectSettings?.int_linkedin_access_token]);

  useEffect(() => {
    if (currentProjectSettings?.int_linkedin_access_token) {
      fetchAdAccounts();
    }
  }, [fetchAdAccounts, currentProjectSettings?.int_linkedin_access_token]);

  // Handle OAuth code return
  useEffect(() => {
    const code = localStorage.getItem('Linkedin_code');
    const state = localStorage.getItem('Linkedin_state');
    if (code && state === 'factors') {
      const url = `${getBackendHost()}/integrations/linkedin/auth`;
      fetch(url, {
        method: 'POST',
        body: JSON.stringify({ code })
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error('Auth failed');
          }
          return response;
        })
        .then((response) => {
          if (response.status < 400) {
            response.json().then((e) => {
              setOauthResponse(e);
              // Fetch the ad accounts using the new token
              fetch(`${getBackendHost()}/integrations/linkedin/ad_accounts`, {
                method: 'POST',
                body: JSON.stringify({ access_token: e?.access_token })
              })
                .then((response) => {
                  if (!response.ok) {
                    throw new Error('Failed to fetch ad accounts');
                  }
                  return response;
                })
                .then((response) => {
                  response.json().then((res) => {
                    const jsonRes = JSON.parse(res);
                    const adAccountsNew = getAdAccounts(jsonRes.elements);
                    setAdAccounts(adAccountsNew);
                    localStorage.removeItem('Linkedin_code');
                    localStorage.removeItem('Linkedin_state');
                    setShowForm(true);
                  });
                })
                .catch((err) => {
                  UiMessage.error('Failed to fetch linkedin/ad_accounts');
                });
            });
          } else {
            logger.log('Failed to fetch linkedin/ad_accounts');
          }
        })
        .catch((err) => {
          UiMessage.error('Failed to fetch linkedin/auth');
        });
    }
  }, []);

  // Render "Connect Now" button if not authenticated
  const renderLinkedinLogin = () => {
    if (!currentProjectSettings?.int_linkedin_access_token) {
      const { hostname, protocol, port } = window.location;
      let redirect_uri = `${protocol}//${hostname}${port ? `:${port}` : ''}`;
      const href = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${BUILD_CONFIG.linkedin_client_id}&redirect_uri=${redirect_uri}&state=factors&scope=r_basicprofile%20r_liteprofile%20r_ads_reporting%20rw_ads%20rw_conversions%20rw_dmp_segments`;
      return (
        <a href={href} className='ant-btn ant-btn-primary'>
          Connect Now
        </a>
      );
    }
  };

  // Account selection functions
  const onAccountSelect = (e) => {
    const { value, checked } = e.target;
    setSelectedAdAccount((prev) => {
      if (checked) {
        return prev.includes(value) ? prev : [...prev, value];
      } else {
        return prev.filter((acc) => acc !== value);
      }
    });
  };

  const toggleSelectAll = (e) => {
    if (e.target.checked && adAccounts) {
      setSelectedAdAccount(adAccounts.map((acc) => acc.value.toString()));
    } else {
      setSelectedAdAccount([]);
    }
  };

  const addManualAccount = () => {
    const trimmedId = manualAccount.trim();
    if (trimmedId !== '') {
      // Add manual account to the selection list
      setSelectedAdAccount((prev) => [...prev, trimmedId]);
    }
    setShowManualModal(false);
  };

  // Submit the account selection via checkboxes
  const handleSubmit = (e) => {
    e.preventDefault();
    factorsai.track('INTEGRATION', {
      name: 'linkedin',
      activeProjectID: activeProject.id
    });
    if (selectedAdAccount && selectedAdAccount.length > 0) {
      const data = {
        int_linkedin_ad_account: selectedAdAccount.join(','),
        int_linkedin_refresh_token: oauthResponse?.refresh_token,
        int_linkedin_refresh_token_expiry:
          oauthResponse?.refresh_token_expires_in,
        project_id: activeProject.id.toString(),
        int_linkedin_access_token: oauthResponse?.access_token,
        int_linkedin_access_token_expiry: oauthResponse?.expires_in
      };
      addLinkedinAccessToken(data)
        .then(() => {
          fetchProjectSettings(activeProject.id);
          setShowForm(false);
          UiMessage.success('LinkedIn integration enabled!');
          sendSlackNotification(
            currentAgent.email,
            activeProject.name,
            'Linkedin'
          );
        })
        .catch((e) => {
          logger.error(e);
          UiMessage.error('Error enabling integration');
          setShowForm(false);
        });
    }
  };

  // Submit when using the manual account modal
  const handleManualSubmit = (e) => {
    e.preventDefault();
    factorsai.track('INTEGRATION', {
      name: 'linkedin',
      activeProjectID: activeProject.id
    });
    if (manualAccount.trim() !== '') {
      const data = {
        int_linkedin_ad_account: manualAccount.trim(),
        int_linkedin_refresh_token: oauthResponse?.refresh_token,
        int_linkedin_refresh_token_expiry:
          oauthResponse?.refresh_token_expires_in,
        project_id: activeProject.id.toString(),
        int_linkedin_access_token: oauthResponse?.access_token,
        int_linkedin_access_token_expiry: oauthResponse?.expires_in
      };
      addLinkedinAccessToken(data)
        .then(() => {
          fetchProjectSettings(activeProject.id);
          setShowForm(false);
          setShowManualModal(false);
          UiMessage.success('LinkedIn integration enabled!');
          sendSlackNotification(
            currentAgent.email,
            activeProject.name,
            'Linkedin'
          );
        })
        .catch((e) => {
          logger.error(e);
          UiMessage.error('Error enabling integration');
          setShowForm(false);
          setShowManualModal(false);
        });
    }
  };

  // Disconnect integration
  const onDisconnect = () => {
    UiModal.confirm({
      title: 'Are you sure you want to disable this?',
      content:
        'You are about to disable this integration. Factors will stop bringing in data from this source.',
      okText: 'Disconnect',
      cancelText: 'Cancel',
      onOk: () => {
        setLoading(true);
        deleteIntegration(activeProject.id, 'linkedin')
          .then(() => {
            fetchProjectSettings(activeProject.id);
            setLoading(false);
            setShowForm(false);
            setTimeout(() => {
              UiMessage.success('LinkedIn integration disconnected!');
            }, 500);
            integrationCallback();
          })
          .catch((err) => {
            UiMessage.error(`${err?.data?.error}`);
            setShowForm(false);
          });
      },
      onCancel: () => {}
    });
  };

  useEffect(() => {
    // Initialize selectedAdAccount when showForm becomes true
    if (showForm && currentProjectSettings?.int_linkedin_ad_account) {
      const selectedIds =
        currentProjectSettings.int_linkedin_ad_account.split(',');
      setSelectedAdAccount(selectedIds);
    }
  }, [showForm, currentProjectSettings]);

  // Modal for selecting LinkedIn account(s)
  const accountSelectionModal = () => {
    return (
      <UiModal
        visible={showForm}
        zIndex={1020}
        afterClose={() => setShowForm(false)}
        className={`fa-modal--regular fa-modal--slideInDown ${styles?.antModal}`}
        centered
        footer={null}
        transitionName=''
        maskTransitionName=''
        closable={false}
        onCancel={() => setShowForm(false)}
      >
        <div className='p-2'>
          <Row gutter={[0, 16]}>
            <Col span={24}>
              <Text type='title' level={6} weight='bold' extraClass='m-0'>
                Manage LinkedIn Ad Accounts
              </Text>
              <Text type='title' level={7} color='grey' extraClass='m-0 mt-1'>
                Please select the LinkedIn Ad Accounts you want to connect.
              </Text>
            </Col>
          </Row>
          <form onSubmit={handleSubmit} className='w-full'>
            <Row className='mt-4'>
              <Col span={24}>
                <div style={{ maxHeight: '400px', overflow: 'auto' }}>
                  <table className='w-full'>
                    <thead>
                      <tr>
                        <th className='p-2'>
                          <UiCheckbox
                            onChange={toggleSelectAll}
                            checked={
                              adAccounts &&
                              selectedAdAccount.length === adAccounts.length
                            }
                          />
                        </th>
                        <th className='p-2 text-sm font-semibold whitespace-nowrap'>
                          Customer Id
                        </th>
                        <th className='p-2 text-sm font-semibold truncate'>
                          Customer Name
                        </th>
                        <th className='p-2 text-sm font-semibold'>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {adAccounts &&
                        adAccounts?.map((acc) => (
                          <tr key={acc?.value} className='border-t'>
                            <td className='p-2'>
                              <UiCheckbox
                                value={acc?.value?.toString()}
                                onChange={onAccountSelect}
                                checked={selectedAdAccount?.includes(
                                  acc?.value?.toString()
                                )}
                              />
                            </td>
                            <td className='p-2 text-sm font-semibold'>
                              {acc?.value}
                            </td>
                            <td className='p-2 text-sm font-semibold truncate'>
                              {acc?.name}
                            </td>
                            <td className='p-2 whitespace-nowrap'>
                              <UiIcon
                                type='solid'
                                className={
                                  acc?.status === 'ACTIVE'
                                    ? 'text-green-5'
                                    : 'text-red-4'
                                }
                                name={
                                  acc?.status === 'ACTIVE' ? 'check' : 'xmark'
                                }
                                size='1x'
                              />
                              <span className={`text-sm ml-2`}>
                                {acc?.status}
                              </span>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </Col>
            </Row>
            <Row className='mt-4'>
              <Col span={24}>
                <div className='flex justify-end items-center'>
                  <UiButton
                    type='link'
                    onClick={() => {
                      setShowForm(false);
                      setShowManualModal(true);
                    }}
                    className='mr-4'
                  >
                    Enter Id Manually
                  </UiButton>
                  <UiButton
                    type='default'
                    className='ant-btn-primary'
                    disabled={selectedAdAccount?.length === 0}
                    htmlType='submit'
                  >
                    Update and Close
                  </UiButton>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </UiModal>
    );
  };

  // Display currently selected account details with an Edit button
  const displaySelectedAccount = () => {
    if (
      currentProjectSettings?.int_linkedin_ad_account &&
      currentProjectSettings?.int_linkedin_ad_account !== ''
    ) {
      const selectedIds =
        currentProjectSettings?.int_linkedin_ad_account.split(',');
      return (
        <div className='mt-4 flex flex-col w-full'>
          <Text
            type='title'
            level={6}
            color='character-primary'
            extraClass='m-0'
          >
            Selected LinkedIn Account
          </Text>
          <div
            className='mt-4 p-4 relative'
            style={{
              background: '#fafafa',
              borderRadius: 12,
              cursor: 'pointer'
            }}
            onClick={() => setShowForm(true)}
          >
            {adAccountLoading ? (
              <div className='w-full'>
                <UiSkeleton active />
              </div>
            ) : (
              <>
                {selectedIds?.map((id, i) => {
                  const acc =
                    adAccounts &&
                    adAccounts?.find((a) => a?.value?.toString() === id);
                  return (
                    <div
                      key={id}
                      className={`flex gap-6 items-center ${
                        i !== 0 ? 'mt-2' : ''
                      }`}
                    >
                      {acc && (
                        <UiIcon
                          type='solid'
                          className={
                            acc.status === 'ACTIVE'
                              ? 'text-green-5'
                              : 'text-red-4'
                          }
                          name={acc?.status === 'ACTIVE' ? 'check' : 'xmark'}
                          size='1x'
                        />
                      )}
                      <div className='flex space-between w-full'>
                        <div className='flex items-center'>
                          <Text
                            type='title'
                            level={7}
                            extraClass='m-0'
                            color='character-secondary'
                          >
                            <NumberFormat
                              displayType='text'
                              thousandSeparator='-'
                              value={id}
                              format='###-###-####'
                            />
                          </Text>
                          {acc && (
                            <Text
                              key={acc?.value}
                              type='title'
                              level={7}
                              extraClass='m-0 ml-4'
                              color='character-secondary'
                            >
                              {acc?.name}
                            </Text>
                          )}
                        </div>
                        <div>
                          {acc && (
                            <Text
                              key={acc?.value + '_status'}
                              type='title'
                              level={7}
                              extraClass='m-0 ml-4'
                              color='character-secondary'
                            >
                              {acc?.status}
                            </Text>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </>
            )}
            <div className='absolute' style={{ top: 16, right: 16 }}>
              <SVG name='Edit' size={16} color='#00000073' />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <ErrorBoundary
      fallback={
        <FaErrorComp subtitle='Facing issues with LinkedIn integrations' />
      }
      onError={FaErrorLog}
    >
      <div className='flex'>{displaySelectedAccount() || null}</div>
      {showForm && accountSelectionModal()}
      {showManualModal && (
        <UiModal
          visible={showManualModal}
          onCancel={() => setShowManualModal(false)}
          footer={null}
          title='Enter Manual LinkedIn Ad Account'
          centered
        >
          <form onSubmit={handleManualSubmit}>
            <UiInput
              placeholder='Enter Ad Account'
              value={manualAccount}
              onChange={(e) => setManualAccount(e.target.value)}
            />
            <div className='flex justify-end mt-3'>
              <UiButton
                type='default'
                onClick={() => setShowManualModal(false)}
              >
                Cancel
              </UiButton>
              <UiButton
                htmlType='submit'
                type='primary'
                disabled={!manualAccount.trim()}
                className='ml-2'
              >
                Submit
              </UiButton>
            </div>
          </form>
        </UiModal>
      )}
      <div className='mt-4 flex'>
        {currentProjectSettings?.int_linkedin_ad_account ? (
          <UiButton loading={loading} onClick={onDisconnect}>
            Disconnect
          </UiButton>
        ) : (
          renderLinkedinLogin()
        )}
      </div>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state) => ({
  activeProject: state.global.active_project,
  currentProjectSettings: state.global.currentProjectSettings,
  currentAgent: state.agent.agent_details
});

export default connect(mapStateToProps, {
  addLinkedinAccessToken,
  fetchProjectSettings,
  udpateProjectSettings,
  deleteIntegration
})(LinkedInIntegration);
